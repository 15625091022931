import alpha_c1 from './img/equipos/alphajet/c1.jpg'
import alpha_c5 from './img/equipos/alphajet/c5.jpg'
import alpha_c6 from './img/equipos/alphajet/c6.jpg'
import alpha_c9 from './img/equipos/alphajet/c9.jpg'

import blue_1 from './img/equipos/blue/b1.jpg'
import blue_2 from './img/equipos/blue/b2.jpg'
import blue_3 from './img/equipos/blue/b3.jpg'
import blue_4 from './img/equipos/blue/b4.jpg'
import blue_5 from './img/equipos/blue/b5.jpg'

import hid_1 from './img/equipos/hid/h1.jpg'

import kb_1 from './img/equipos/kb/kb1.jpg'
import kb_2 from './img/equipos/kb/kb2.jpg'
import kb_3 from './img/equipos/kb/kb3.jpg'
import kb_4 from './img/equipos/kb/kb4.jpg'

import n_1 from './img/equipos/newland/n1.jpg'
import n_2 from './img/equipos/newland/n2.jpg'
import n_3 from './img/equipos/newland/n3.jpg'
import n_4 from './img/equipos/newland/n4.jpg'
import n_5 from './img/equipos/newland/n5.jpg'
import n_6 from './img/equipos/newland/n6.jpg'
import n_7 from './img/equipos/newland/n7.jpg'
import n_8 from './img/equipos/newland/n8.jpg'
import n_9 from './img/equipos/newland/n9.jpg'
import n_10 from './img/equipos/newland/n10.jpg'
import n_11 from './img/equipos/newland/n11.jpg'
import n_12 from './img/equipos/newland/n12.jpg'

import s_1 from './img/equipos/sewoo/s1.jpg'
import s_2 from './img/equipos/sewoo/s2.jpg'
import s_3 from './img/equipos/sewoo/s3.jpg'
import s_4 from './img/equipos/sewoo/s4.jpg'
import s_5 from './img/equipos/sewoo/s5.jpg'
import s_6 from './img/equipos/sewoo/s6.jpg'
import s_7 from './img/equipos/sewoo/s7.jpg'

import t_1 from './img/equipos/tsc/t1.jpg'
import t_2 from './img/equipos/tsc/t2.jpg'
import t_3 from './img/equipos/tsc/t3.jpg'
import t_4 from './img/equipos/tsc/t4.jpg'
import t_5 from './img/equipos/tsc/t5.jpg'
import t_6 from './img/equipos/tsc/t6.jpg'

import u_1 from './img/equipos/united/u1.jpg'
import u_2 from './img/equipos/united/u2.jpg'
import u_3 from './img/equipos/united/u3.jpg'
import u_4 from './img/equipos/united/u4.jpg'
import u_5 from './img/equipos/united/u5.jpg'

export const products = [
    {
      name: 'AlphaJet 1',
      brand: 'AlphaJet',
      image: alpha_c1
    }, {
      name: 'AlphaJet 5',
      brand: 'AlphaJet',
      image: alpha_c5
    }, {
      name: 'AlphaJet 6',
      brand: 'AlphaJet',
      image: alpha_c6
    }, {
      name: 'AlphaJet 9',
      brand: 'AlphaJet',
      image: alpha_c9
    }, {
      name: 'Blue 1',
      brand: 'BLUE',
      image: blue_1
    }, {
      name: 'Blue 2',
      brand: 'BLUE',
      image: blue_2
    }, {
      name: 'Blue 3',
      brand: 'BLUE',
      image: blue_3
    }, {
      name: 'Blue 4',
      brand: 'BLUE',
      image: blue_4
    }, {
      name: 'Blue 5',
      brand: 'BLUE',
      image: blue_5
    }, {
      name: 'hid 1',
      brand: 'HID',
      image: hid_1
    }, {
      name: 'K&B 1',
      brand: 'K&B',
      image: kb_1
    }, {
      name: 'K&B 2',
      brand: 'K&B',
      image: kb_2
    }, {
      name: 'K&B 3',
      brand: 'K&B',
      image: kb_3
    }, {
      name: 'K&B 4',
      brand: 'K&B',
      image: kb_4
    }, {
      name: 'Newland 1',
      brand: 'Newland',
      image: n_1
    }, {
      name: 'Newland 2',
      brand: 'Newland',
      image: n_2
    }, {
      name: 'Newland 3',
      brand: 'Newland',
      image: n_3
    }, {
      name: 'Newland 4',
      brand: 'Newland',
      image: n_4
    }, {
      name: 'Newland 5',
      brand: 'Newland',
      image: n_5
    }, {
      name: 'Newland 6',
      brand: 'Newland',
      image: n_6
    }, {
      name: 'Newland 7',
      brand: 'Newland',
      image: n_7
    }, {
      name: 'Newland 8',
      brand: 'Newland',
      image: n_8
    }, {
      name: 'Newland 9',
      brand: 'Newland',
      image: n_9
    }, {
      name: 'Newland 10',
      brand: 'Newland',
      image: n_10
    }, {
      name: 'Newland 11',
      brand: 'Newland',
      image: n_11
    }, {
      name: 'Newland 12',
      brand: 'Newland',
      image: n_12
    }, {
      name: 'Sewoo 1',
      brand: 'SEWOO',
      image: s_1
    }, {
      name: 'Sewoo 2',
      brand: 'SEWOO',
      image: s_2
    }, {
        name: 'Sewoo 3',
        brand: 'SEWOO',
        image: s_3
      }, {
        name: 'Sewoo 4',
        brand: 'SEWOO',
        image: s_4
      }, {
        name: 'Sewoo 5',
        brand: 'SEWOO',
        image: s_5
      }, {
        name: 'Sewoo 6',
        brand: 'SEWOO',
        image: s_6
      }, {
        name: 'Sewoo 7',
        brand: 'SEWOO',
        image: s_7
      }, {
        name: 'TSC 1',
        brand: 'TSC',
        image: t_1
      }, {
        name: 'TSC 2',
        brand: 'TSC',
        image: t_2
      }, {
        name: 'TSC 3',
        brand: 'TSC',
        image: t_3
      }, {
        name: 'TSC 4',
        brand: 'TSC',
        image: t_4
      }, {
        name: 'TSC 5',
        brand: 'TSC',
        image: t_5
      }, {
        name: 'TSC 6',
        brand: 'TSC',
        image: t_6
      }, {
        name: 'United Barcode 1',
        brand: 'UNITED BARCODE',
        image: u_1
      }, {
        name: 'United Barcode 2',
        brand: 'UNITED BARCODE',
        image: u_2
      },{
        name: 'United Barcode 3',
        brand: 'UNITED BARCODE',
        image: u_3
      },{
        name: 'United Barcode 4',
        brand: 'UNITED BARCODE',
        image: u_4
      },{
        name: 'United Barcode 5',
        brand: 'UNITED BARCODE',
        image: u_5
      },
    
  ]